import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { H1, A } from "../components/commons"

const Section = styled.section`
  max-width: 746px;
  margin: 0 auto;
  padding: 0 0.75rem;
`

const H2 = styled.h2`
  text-align: left;
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1.375rem;
  color: #9a6e67;
  line-height: 2.6rem;
  margin-top: 0;
  margin-bottom: 0;
`

const P = styled.p`
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #615c53;
  line-height: 1.35rem;
  margin-bottom: 1.35rem;
  text-align: justify;
`

function AboutPage() {
  return (
    <Layout>
      <SEO title="about" />
      <Section>
        <H1>about</H1>
        <P>
          Welcome to <strong>lång tid</strong>, swedish word which means "for a
          long time".
        </P>
        <H2>Concept</H2>
        <P>
          We desire to share everyday looks, able to span times. We try to focus
          on quality clothing and brands that tell a story. Such as{" "}
          <A href="http://dantonfrance.com/">Danton</A> was manufacturing
          traditional French workwear products or{" "}
          <A href="https://kleman-france.com/">Kleman</A>, authentic french
          shoemaker whose the manufacturing remains exclusively carried out in
          the Anjou (France) workshop.
        </P>
        <H2>Contributing</H2>
        <P>
          <strong>lång tid</strong> is an open area. You can suggest look,
          content or idea (in link with the concept) by contacting us on{" "}
          <A href="https://www.instagram.com/langtid.co/">Instagram</A> (send us
          a direct message). Contributions and opinions will always be welcome.
        </P>

        <P>Looking forward to share with you!</P>
      </Section>
    </Layout>
  )
}

export default AboutPage
